import {EditorSDK} from '@wix/platform-editor-sdk';

import {openDashboardModal} from '@/panels';
import {MultilingualDashboardUrlPath, MultilingualPages} from '@/types';

export const handleOpenDashboard = (
  editorSDK: EditorSDK,
  token: string,
  openDashboardPayload?: Record<string, any>,
) => {
  const dashboardPageToOpen = openDashboardPayload?.pageToOpen;
  const currentLanguage = openDashboardPayload?.currentLanguage;
  const sidePanelSection = openDashboardPayload?.sidePanelSection;
  let dashboardUrlToOpen = MultilingualDashboardUrlPath.TRANSLATION_MANAGER;

  if (dashboardPageToOpen === MultilingualPages.MANAGE_LANGUAGES) {
    dashboardUrlToOpen = MultilingualDashboardUrlPath.MANAGE_LANGUAGES;
  }

  openDashboardModal(editorSDK, token, dashboardUrlToOpen, {
    secondaryLanguage: currentLanguage,
    sidePanelSection,
  });
};
