import {EditorSDK} from '@wix/platform-editor-sdk';

export const handleBeforeRemoveApp = async (editorSDK: EditorSDK, token: string) => {
  const origLang = await editorSDK.language.original.get(token);
  const translationLanguages = await editorSDK.language.getTranslationLanguages(token);
  const allComponents = await editorSDK.document.components.getAllComponents(token);
  let index = 0;
  while (index < allComponents?.length) {
    const componentRef = allComponents[index];
    const componentRefType = await editorSDK.document.components.getType(token, {
      componentRef,
    });
    if (!componentRef.id.includes('_r_')) {
      /* checking it's not a referred component. https://jira.wixpress.com/browse/DM-6188*/
      if (componentRefType === 'wysiwyg.viewer.components.LanguageSelector') {
        editorSDK.document.components.remove(token, {componentRef});
      } else {
        translationLanguages.forEach((lang) => {
          editorSDK.language.component.data.remove(token, {
            languageCode: lang.code,
            componentRef,
          });
        });
      }
    }
    index++;
  }
  editorSDK.language.setLanguages(token, {
    originalLanguage: {...origLang, languageCode: origLang.code},
    translationLanguages: [],
  });
};
