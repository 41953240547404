import {EditorSDK} from '@wix/platform-editor-sdk';

import {openPackagePicker} from '@/panels';

export const handleOpenPackagePicker = (
  editorSDK: EditorSDK,
  token: string,
  openPackagePickerPayload?: Record<string, any>,
) => {
  openPackagePicker(
    editorSDK,
    token,
    {
      words_in_credit: openPackagePickerPayload?.wordsInCredit,
      mainLanguage: openPackagePickerPayload?.mainLanguage,
      secondaryLanguage: openPackagePickerPayload?.secondaryLanguage,
      origin: openPackagePickerPayload?.origin,
      words_translated: openPackagePickerPayload?.translatedWords,
      words_remain: openPackagePickerPayload?.wordsRemain,
      words_needed: openPackagePickerPayload?.wordsNeeded,
      is_credit_enough: openPackagePickerPayload?.hasEnoughWords,
    },
    openPackagePickerPayload?.modalTitle,
  );
};
