import {EditorSDK, EditorType} from '@wix/platform-editor-sdk';
import {EditorScriptFlowAPI} from '@wix/yoshi-flow-editor';

import {createGetSiteLanguages} from '@/api';
import {addLanguageSelector} from '@/components';
import {openDashboardModal} from '@/panels';
import {MultilingualDashboardUrlPath} from '@/types';

type HandleAppInstalled = (
  editorSDK: EditorSDK,
  fedopsLogger: EditorScriptFlowAPI['fedops'],
  biLogger: EditorScriptFlowAPI['bi'],
  editorType: EditorType,
  appInstance: string,
) => Promise<void>;

export const handleAppInstalled: HandleAppInstalled = async (
  editorSDK,
  fedopsLogger,
  biLogger,
  editorType,
  appInstance,
) => {
  fedopsLogger.interactionStarted('appInstalledEvent');

  const getSiteLanguages = createGetSiteLanguages(appInstance);

  const {originalLanguage, translationLanguages} = await getSiteLanguages();

  const hasPrimaryLanguage = originalLanguage.length > 0;
  const hasSecondaryLanguages = translationLanguages.length > 0;

  await addLanguageSelector(editorSDK, '', editorType, biLogger);

  /** We want to open onboarding only if no languages are set.
   * In installing from editor it's always true, but if installed from dashboard and added languages from there we don't want to open onboarding */
  !hasPrimaryLanguage &&
    !hasSecondaryLanguages &&
    (await openDashboardModal(editorSDK, '', MultilingualDashboardUrlPath.ONBOARDING));

  fedopsLogger.interactionEnded('appInstalledEvent');
};
