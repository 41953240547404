import {EditorSDK} from '@wix/platform-editor-sdk';

import {MultilingualDashboardUrlPath} from '@/types';
import {createDashboardUrl} from '@/utils';

export const openPackagePicker = async (
  editorSDK: EditorSDK,
  token: string,
  params?: Record<string, any>,
  modalTitle?: string,
) => {
  const metaSiteId = await editorSDK.document.info.getMetaSiteId(token);

  const url = createDashboardUrl(
    metaSiteId,
    MultilingualDashboardUrlPath.PACKAGE_PICKER,
    params || {},
  );

  await editorSDK.editor.openModalPanel(token, {
    title: modalTitle ?? 'Auto Translate Word Credits',
    url: url.toString(),
    width: '94%' as any,
    height: '94%' as any,
    centered: true,
  });
};
