import {EditorSDK, LanguageDefinition} from '@wix/platform-editor-sdk';

import {createGetSiteLanguages} from '@/api';
import {enrichLanguage} from '@/helpers';
import {MultilingualDashboardUrlPath} from '@/types';

export const openDashboardModal = async (
  editorSDK: EditorSDK,
  token: string,
  dashboardUrlPath: MultilingualDashboardUrlPath,
  queryParamsMap?: Record<string, string>,
) => {
  const appInstance = await editorSDK.info.getAppInstance(token);
  const getSiteLanguages = createGetSiteLanguages(appInstance);

  await editorSDK.editor.openDashboardPanel(token, {
    url: queryParamsMap
      ? `${dashboardUrlPath}?${new URLSearchParams(queryParamsMap).toString()}`
      : dashboardUrlPath,
    closeOtherPanels: true,
  });

  const {originalLanguage, translationLanguages} = await getSiteLanguages();

  if (originalLanguage.length > 0) {
    editorSDK.language.setLanguages(token, {
      originalLanguage: enrichLanguage(originalLanguage[0]) as LanguageDefinition,
      translationLanguages: translationLanguages.map(enrichLanguage) as LanguageDefinition[],
    });
  }
};
