export const createDashboardUrl = (
  metaSiteId: string,
  path: string,
  params: Record<string, any>,
) => {
  const baseUrl = `https://manage.wix.com/dashboard/${metaSiteId}/${path}`;
  const url = new URL(baseUrl);

  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });

  return url;
};
