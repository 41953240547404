import {EditorSDK, EditorType} from '@wix/platform-editor-sdk';
import {EditorScriptFlowAPI} from '@wix/yoshi-flow-editor';

import {languageSelectorClassicStructure} from './languageSelectorClassicStructure';
import {languageSelectorResponsiveStructure} from './languageSelectorResponsiveStructure';

export const addLanguageSelector = async (
  editorSDK: EditorSDK,
  token: string,
  editorType: EditorType,
  biLogger: EditorScriptFlowAPI['bi'],
) => {
  const languageSelectorComponents = await editorSDK.document.components.findAllByType(token, {
    componentType: languageSelectorClassicStructure.componentType,
  });

  if (languageSelectorComponents.length === 0) {
    const siteHeader = await editorSDK.siteSegments.getHeader(token);
    const currentPageRef = await editorSDK.document.pages.getCurrent(token);

    const componentDefinition =
      editorType === EditorType.Classic
        ? languageSelectorClassicStructure
        : editorType === EditorType.Responsive
        ? languageSelectorResponsiveStructure
        : undefined;

    if (!componentDefinition) {
      return;
    }

    await editorSDK.components.add(token, {
      componentDefinition,
      pageRef: siteHeader,
    });

    biLogger?.log({
      evid: 289,
      container_id: siteHeader.id,
      page_id: currentPageRef.id,
      main_language: 'undefined',
    });
  }
};
