export const languageSelectorResponsiveStructure = {
  parent: 'comp-kbgajy18',
  activeVariants: [],
  referredVariants: {},
  scopedStyles: {},
  style: {
    styleType: 'custom',
    componentClassName: 'wysiwyg.viewer.components.LanguageSelector',
    style: {
      properties: {
        backgroundColorHover: 'color_11',
        backgroundColor: 'color_11',
        itemFont: 'normal normal 700 16px/1.4em poppins-extralight',
        boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        borderSides: 'none',
        itemSpacing: '5px',
        backgroundColorActive: 'color_16',
        borderWidth: '1',
        borderRadius: '0px 0px 0px 0px',
        itemTextColor: 'color_15',
        separatorColor: 'color_15',
        'alpha-itemTextColorHover': '1',
        'alpha-backgroundColorActive': '0.8',
        itemTextColorHover: 'color_12',
        borderColorHover: 'rgba(32, 32, 32, 1)',
        borderColorActive: 'rgba(32, 32, 32, 1)',
        itemTextColorActive: 'color_15',
        'boxShadowToggleOn-boxShadow': 'false',
        'alpha-itemTextColor': '1',
        borderColor: 'rgba(24,24,24,1)',
      },
      propertiesSource: {
        backgroundColorHover: 'theme',
        backgroundColor: 'theme',
        itemFont: 'value',
        boxShadow: 'value',
        borderSides: 'value',
        itemSpacing: 'value',
        backgroundColorActive: 'theme',
        borderWidth: 'value',
        borderRadius: 'value',
        itemTextColor: 'theme',
        separatorColor: 'theme',
        'alpha-itemTextColorHover': 'value',
        'alpha-backgroundColorActive': 'value',
        itemTextColorHover: 'theme',
        borderColorHover: 'value',
        borderColorActive: 'value',
        itemTextColorActive: 'theme',
        'boxShadowToggleOn-boxShadow': 'value',
        'alpha-itemTextColor': 'value',
        borderColor: 'value',
      },
      groups: {},
    },
    pageId: '',
    compId: '',
    type: 'ComponentStyle',
    skin: 'wysiwyg.viewer.skins.LanguageSelectorSkin',
  },
  activeModes: {},
  data: {
    type: 'LanguageSelector',
  },
  layouts: {
    containerLayout: {},
    componentLayout: {
      height: {
        type: 'px',
        value: 45,
      },
      width: {
        type: 'px',
        value: 166.59375,
      },
      type: 'ComponentLayout',
      hidden: false,
    },
    itemLayout: {
      alignSelf: 'center',
      margins: {
        left: {
          type: 'percentage',
          value: 0,
        },
        right: {
          type: 'percentage',
          value: 0,
        },
        top: {
          value: 0,
          type: 'px',
        },
        bottom: {
          type: 'px',
          value: 0,
        },
      },
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'center',
      type: 'GridItemLayout',
    },
    type: 'SingleLayoutData',
  },
  componentType: 'wysiwyg.viewer.components.LanguageSelector',
  scopedLayouts: {},
  layout: {
    x: 0,
    fixedPosition: false,
    y: 111,
    scale: 1,
    height: 90,
    rotationInDegrees: 0,
    width: 160,
  },
  type: 'Component',
  props: {
    itemFormat: 'shortName',
    iconType: 'none',
    iconSize: 22,
    alignTabs: 'left',
    displayMode: 'dropdown',
    type: 'LanguageSelectorProperties',
    hasArrow: true,
    languagesOrder: [],
  },
  metaData: {
    sig: 'epn-31',
    pageId: 'masterPage',
  },
  skin: 'wysiwyg.viewer.skins.LanguageSelectorSkin',
};
