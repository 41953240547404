export const languageSelectorClassicStructure = {
  type: 'Component',
  skin: 'wysiwyg.viewer.skins.LanguageSelectorSkin',
  layout: {
    width: 168,
    height: 45,
    x: 280,
    y: 30,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'wysiwyg.viewer.components.LanguageSelector',
  props: {
    type: 'LanguageSelectorProperties',
    metaData: {
      schemaVersion: '1.0',
      autoGenerated: false,
    },
    displayMode: 'dropdown',
    iconType: 'none',
    iconSize: 22,
    itemFormat: 'shortName',
    alignTabs: 'left',
    hasArrow: true,
    languagesOrder: [],
  },
  style: {
    type: 'TopLevelStyle',
    styleType: 'custom',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    style: {
      properties: {
        'alpha-itemTextColor': '1',
        'alpha-itemTextColorHover': '1',
        backgroundColor: 'color_11',
        backgroundColorActive: 'color_12',
        backgroundColorHover: 'color_11',
        borderColor: 'rgba(149,149,149,1)',
        borderColorActive: 'rgba(32, 32, 32, 1)',
        borderColorHover: 'rgba(32, 32, 32, 1)',
        borderRadius: '0px 0px 0px 0px',
        borderSides: 'none',
        borderWidth: '1',
        boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'boxShadowToggleOn-boxShadow': 'false',
        itemFont: 'font_8',
        itemSpacing: '5px',
        itemTextColor: 'color_15',
        itemTextColorActive: 'color_15',
        itemTextColorHover: 'color_14',
        separatorColor: 'color_11',
      },
      propertiesSource: {
        'alpha-itemTextColor': 'value',
        'alpha-itemTextColorHover': 'value',
        backgroundColor: 'theme',
        backgroundColorActive: 'theme',
        backgroundColorHover: 'theme',
        borderColor: 'value',
        borderColorActive: 'value',
        borderColorHover: 'value',
        borderRadius: 'value',
        borderSides: 'value',
        borderWidth: 'value',
        boxShadow: 'value',
        'boxShadowToggleOn-boxShadow': 'value',
        itemFont: 'theme',
        itemSpacing: 'value',
        itemTextColor: 'theme',
        itemTextColorActive: 'theme',
        itemTextColorHover: 'theme',
        separatorColor: 'theme',
      },
      groups: {},
    },
    componentClassName: 'wysiwyg.viewer.components.LanguageSelector',
    pageId: '',
    compId: '',
    skin: 'wysiwyg.viewer.skins.LanguageSelectorSkin',
  },
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'WixCodeConnectionItem',
        role: 'languageSelector17',
      },
    ],
  },
  activeModes: {},
} as any;
