import {Language} from '@wix/multilingual-languages-utils';
import {LanguageDefinition} from '@wix/platform-editor-sdk';

export const enrichLanguage = (language: Language): LanguageDefinition => {
  return {
    ...language,
    regionCode: language.regionCode || '',
    status: language.status || 'Inactive',
    languageCode: language.id,
    twoLettersLanguageCode: language.languageCode!,
    languageLocalizedName: language.localizedName,
  };
};
